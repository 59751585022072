import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { update } from './store/features/reloadSlice';
import store from './store/store';

const element = document.getElementById('get_quote_widget');
const root = ReactDOM.createRoot(element);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App form={element?.dataset?.form} />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
// const configuration = {
//   onUpdate: registration => {
//     if (registration && registration.waiting) {
//       store.dispatch(update(registration));
//     }
//   },
// };

// serviceWorkerRegistration.register(configuration);
