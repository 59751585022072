import styled from 'styled-components';

export const MainWrapper = styled.div`
  min-height: 100dvh;
  max-height: 100dvh;
  display: flex;
  flex-direction: row;
  background: #f2c36d;

  .wrapper {
    align-items: center;
    border-radius: 100px 16px 16px 100px;
    width: 100%;
    height: fit-content;
  }
`;
