import React, { useContext } from 'react';
// import { ReactComponent as Close } from '../../assets/images/close.svg';
// import { ReactComponent as Info } from '../../assets/images/info.svg';
import { ModalContext } from '../../context/ModalContext';
import { initModal } from '../../helpers/utils';

const SelectionInfo = () => {
  const { modal, setModal } = useContext(ModalContext);
  const { selectedComponent } = modal.content || {};

  return (
    <div className="tw-max-w-full tw-w-96">
      <div className="border-bottom tw-flex tw-items-center tw-px-6 tw-py-5">
        {/* <Info className="primary-500-text tw-mr-2" width={24} height={24} /> */}
        <p className="inter-700-text font-24 natural-900-text tw-flex-1">Info</p>
        <button onClick={() => setModal(initModal)}>{/* <Close className="" width={24} height={24} /> */}</button>
      </div>
      <div className="tw-p-6">
        {selectedComponent?.image?.url && (
          <div className="tw-flex tw-justify-center">
            <img
              src={selectedComponent.image.url}
              width={selectedComponent.image.width || 200}
              height={selectedComponent.image.height || 300}
            />
          </div>
        )}
        <div className="tw-mt-6">
          <p className="inter-600-text font-14 line-height-20 natural-900-text">{selectedComponent?.name}</p>
          <div className="tw-mt-2">
            <p className="inter-400-text font-12 line-height-20 natural-500-text">{selectedComponent?.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectionInfo;
