import React, { useState } from 'react';
import HttpsRedirect from 'react-https-redirect';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import { LanguageProvider } from './context/LanguageContext';
import { ThemePreferenceContextProvider, initialThemeState } from './context/ThemePreferenceContext';
import { getLanguage } from './helpers/i18n';
import './index.css';
import { Router } from './routes/router';
import { CommonStyleWrapper } from './styles/Common.styled';
import { light } from './styles/theme/index';
import './translations/i18n';

const themesMap = {
  light,
};

const App = ({ form }) => {
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState(getLanguage());
  const [theme, setTheme] = useState(initialThemeState.theme);
  const [themeMapState, setThemeMapState] = useState(themesMap);

  const onLanguageChange = lang => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  return (
    <LanguageProvider value={{ onLanguageChange, language }}>
      <ThemePreferenceContextProvider
        value={{ theme, setTheme, themeMapState, setThemeMapState, initialThemeMapState: themesMap }}>
        <ThemeProvider theme={themeMapState[theme]}>
          <CommonStyleWrapper />
          <div className={`App theme-${theme}`}>
            <HttpsRedirect>
              <Router form={form} />
            </HttpsRedirect>
          </div>
        </ThemeProvider>
      </ThemePreferenceContextProvider>
    </LanguageProvider>
  );
};

export default App;
