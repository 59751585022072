import React, { useContext, useState } from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import Address from '../../components/address';
import Button from '../../components/common/button/button';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import { OrganisationContext } from '../../context/organisationContext';
import { createNumberMask } from '../../helpers/createNumberMask';
import { getJobTypes } from '../../store/features/quoteSlice';

const typeOptions = [
  {
    value: 'SOLAR',
    label: 'Solar',
  },
  {
    value: 'BATTERY',
    label: 'Battery',
  },
  {
    value: 'SOLAR_BATTERY',
    label: 'Solar + Battery',
  },
  {
    value: 'EV_CHARGER',
    label: 'EV Charger',
  },
];

const QuoteDetails = ({ quoteDetails, setQuoteDetails, onGetQuote }) => {
  const { address, bill, type } = quoteDetails || {};
  const { api_key, getConfigIdByType } = useContext(OrganisationContext);

  const project = getConfigIdByType('PROJECT');

  const [loadingAddress, setLoadingAddress] = useState(false);

  const onUpdateQuoteDetails = (key, value) => {
    setQuoteDetails({ ...quoteDetails, [key]: value });
  };
  console.log(bill);
  return (
    <QuoteDetailsWrapper className="w-full h-fit-content items-center col-gap-4">
      <div className="grid-cols">
        <div className="flex input-comp">
          <Address
            address={address?.formatted_address}
            setAddress={value => onUpdateQuoteDetails('address', value)}
            loadingAddress={loadingAddress}
            setLoadingAddress={setLoadingAddress}
          />
        </div>
        <div className="flex-column w-full input-comp">
          <div className="mb-1">
            <span className="inter-500-text natural-900-text">Monthly Electric Bill</span>
          </div>
          <MaskedInput
            mask={createNumberMask({
              prefix: '£',
              allowDecimal: true,
              includeThousandsSeparator: false,
            })}
            disabled={!api_key || loadingAddress}
            value={bill}
            className="input w-full"
            placeholder="£00.00"
            guide={false}
            onChange={({ target: { value } }) => onUpdateQuoteDetails('bill', value)}
          />
        </div>
        {/* <div className="flex input-comp">
          <SearchableDropdown
            defaultAdditional={{
              pageable: false,
              fetchFunction: getJobTypes,
              page: 0,
              payload: { api_key },
              params: {
                project_id: project?.id ? project.id : null,
              },
            }}
            value={type}
            isDisabled={!api_key || loadingAddress}
            onChange={option => onUpdateQuoteDetails('type', option)}
            placeholder="Type"
            name="Type"
          />
        </div> */}
        <div className="flex items-end input-comp">
          <Button
            size="customsize"
            label="Get Quote"
            width="150px"
            className="flex primary specified-width done-btn"
            onClick={onGetQuote}
            loading={loadingAddress}
            disabled={!api_key || !address || !bill}
          />
        </div>
      </div>
    </QuoteDetailsWrapper>
  );
};

const QuoteDetailsWrapper = styled('div')`
  .grid-cols {
    display: grid;
    grid-template-columns: 2fr 1fr 150px;
    column-gap: 16px;

    @media only screen and (max-width: 850px) {
      grid-template-columns: 1fr 1fr;
      .input-comp {
        margin-bottom: 12px;
      }
      .done-btn {
        width: 100%;
      }
    }

    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;

      .done-btn {
        margin-top: 12px;
      }
    }
  }
`;

export default QuoteDetails;
