import React from 'react';
import styled from 'styled-components';
import Button from '../../components/common/button/button';
import IconContainer from '../../components/common/icon-container';
import InputElement from '../../components/common/input';
import PhoneInput from '../../components/common/phone-input';

const CustomerDetails = ({ customerDetails, setCustomerDetails, onDone, loading }) => {
  const { name, email, mobile, country_code } = customerDetails || {};

  const onUpdateCustomerDetails = (key, value) => {
    setCustomerDetails({ ...customerDetails, [key]: value });
  };

  return (
    <CustomerDetailsWrapper className="w-full h-fit-content items-center col-gap-4 customer-details">
      <div className="grid-cols">
        <div className="flex flex-1 input-comp">
          <InputElement
            value={name}
            onChange={value => onUpdateCustomerDetails('name', value)}
            placeholder="Name"
            name="Full name"
          />
        </div>
        <div className="flex flex-1 input-comp">
          <InputElement
            value={email}
            onChange={value => onUpdateCustomerDetails('email', value)}
            placeholder="Email"
            name="Email"
          />
        </div>
        <div className="flex-column w-full input-comp">
          <div className="mb-1">
            <label className="inter-500-text natural-900-text">
              Mobile <span className="natural-400-text">{'(Optional)'}</span>
            </label>
          </div>
          <PhoneInput
            selectedCountry={country_code || '+44'}
            setSelectedCountry={value => onUpdateCustomerDetails('country_code', value)}
            phone={mobile}
            setPhone={value => onUpdateCustomerDetails('mobile', value)}
          />
        </div>
        <div className="flex items-end input-comp">
          <Button
            size="customsize"
            label="Done"
            width="150px"
            loading={loading}
            className="flex primary specified-width mt-3 done-btn"
            disabled={!name || !email}
            onClick={onDone}
          />
        </div>
      </div>
    </CustomerDetailsWrapper>
  );
};

const CustomerDetailsWrapper = styled('div')`
  .grid-cols {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 150px;
    column-gap: 16px;

    @media only screen and (max-width: 850px) {
      grid-template-columns: 1fr 1fr;
      .input-comp {
        margin-bottom: 12px;
      }
      .done-btn {
        width: 100%;
      }
    }

    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;

      .done-btn {
        margin-top: 12px;
      }
    }
  }
`;

export default CustomerDetails;
